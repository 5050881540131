import {Button, Icon} from '@startlibs/components';
import {Errors, FormValue, TextInput, WithForm} from '@startlibs/form';
import _ from 'lodash/fp'
import React, { useEffect, useRef, useState } from 'react'
import {Card, PageContainer} from '../../components/PageLayout';
import {getJwt} from '../../hooks/useJwt';
import {jwtPostFetcher} from '../../utils/authFetch';
import { ThreadCard } from './ThreadCard';
import { setNotification } from '../../components/Notifications';
import { buildValidation, required } from '../../utils/validation';
import { getColor } from '@startlibs/utils';
import styled from 'styled-components';

const ReturnButton = styled(Button) `
  box-shadow: none;
  color: ${getColor('gray60')};
  font-weight: bold;
  margin-bottom: 1.5rem;
  ${Icon} {
    font-size: 20px;
  }
`

export const PreviousMessages = ({caseRequest,setCaseRequest,threads, createContainer = false, buttonNew = false, setShouldReloadData}) => {

  const VIEW = 'VIEW';
  const NEW = 'NEW';
  const REPLY = 'REPLY';
  const [isSending, setIsSending] = useState(false)
  const [mode, setMode] = useState(VIEW)
  const [replyId, setReplyId] = useState(null)
  const msgFormRef = useRef()
  const msgFieldRef = useRef()
  const replyAction = (id) => {
    setReplyId(id)
    setMode(REPLY)
  }

  const preValidationMsg = buildValidation({
    'message': [
      (v) => !v && required(),
      (v) => v?.length > 1000 && 'Message: Maximum character limit of 1000 reached.'
    ]
  })

  useEffect(() => {
    if (replyId) {
      msgFieldRef.current.focus()
    }
  }, [replyId])

  const content = (
    <Card>
      {buttonNew 
        ? mode === VIEW && buttonNew() 
        : <>
          {mode === VIEW && <h3>Previous messages</h3>}
        </>
      }
      {mode === VIEW && threads && threads.length > 0 && threads.map((thread) => <ThreadCard caseRequest={caseRequest} setCaseRequest={setCaseRequest} thread={thread} replyAction={replyAction}/>)}
      {(mode === NEW || mode === REPLY) && <>
        <ReturnButton 
          noShadow rounded color="gray240" icon="return"
          onClick={() => {
          setMode(VIEW)
          setReplyId(null)
        }}>View all messages</ReturnButton>
        {replyId && replyId > 0 && replyId != null && <ThreadCard thread={threads.find((message) => message.id === replyId)} caseRequest={caseRequest} isReplying/>}
        <WithForm
          preValidation={preValidationMsg}
          action={(values) => {
            setIsSending(true)
            return jwtPostFetcher(getJwt())(`/api/collaborations/${replyId}/messages?requestId=${caseRequest.requestId}`, {...values} ,{method: 'POST'})
          }}
          onSuccess={(values) => {
            if(setShouldReloadData){
              setShouldReloadData(true)
            }
            setNotification("Message sent")
            setIsSending(false)
          }}
          ref={msgFormRef}
          onFailure={() => {
            setIsSending(false)
          }}
        >
          {form => <>
            <TextInput
              textarea
              autoResize
              ref={msgFieldRef}
              placeholder="Type your message here..."
              path="message"
              mandatory={true}
              label={mode === NEW ? 'Write your message' : 'Reply message'}
              onChange={(e) => {
                if(e.length > 1000){
                  form.addErrors({message: 'Message: Maximum character limit of 1000 reached.'})
                }else{
                  form.clearErrors('message')
                }
              }}
              bellowDescText={
                <FormValue path="message">{message => {
                  if(message){
                    return <span style={{ 
                      display: 'block',
                      width: '100%',
                      textAlign: 'right'
                    }}>{message?.length+"/1000"}</span>
                  }
                }}</FormValue>
              }
            />
            <Errors css="margin-bottom: 1rem;"/>
            <div css="text-align: right">
              <Button
                highlight
                type="submit"
                icon="email"
                isLoading={isSending}
              >
                Send message
              </Button>
            </div>
          </>}
        </WithForm>
      </>}
    </Card>

  )
  return createContainer 
    ? <PageContainer css="padding: 1rem 1.5rem;  min-height: 600px;">{content}</PageContainer> 
    : content
}
