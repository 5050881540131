
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash/fp';
import styled, { css } from 'styled-components';
import { Dialog, Button, Loading, Icon, DialogContent, CloseIcon, SplitColumnsContainer, DialogTitle } from '@startlibs/components';
import { useToggle } from '@startlibs/core'
import { UIAction } from "../../service/UIAction";
import { useDoAction } from "../../service/hooks/useDoAction";
import { UploaderConfigContext } from "../../service/UploaderConfigContext";
import { getColor, media } from '@startlibs/utils';
import { AutoComplete, Combobox, DatePicker, Errors, Form, RadioboxGroup, SimpleRadiobox, TabRadiobox, TextInput, useProvideErrors, WithForm } from '@startlibs/form';
import { lighten, darken } from 'polished';
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch';
import { UploaderAction } from '../../service/UploaderAction';
import { Tooltip } from '@startlibs/components';
import { FEMALE, GENDER_LABELS, MALE, UNKNOWN, OTHER } from '../../../../../src/javascripts/enums/GenderCarequality';
import { DATE_FORMAT } from '../../../../../src/javascripts/config/DateFormat';
import { wrapPromise } from '../../../../../src/javascripts/hooks/useSuspense';

const ZIP = 'ZIP'
const INSTITUTION = 'INSTITUTION'
const FAILED = 'FAILED'
const IN_PROGRESS = 'IN_PROGRESS'

const TabItem = styled.div`
    background: ${getColor('gray240')};
    padding: 0.25rem 1rem;
    min-height: 3.5rem;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 0px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    margin-right: 0.75rem;
    max-width: 250px;
    ${props => props.active ? css`
        background: #d1e7f5;
        border-bottom: 0px;
        cursor: default;
        flex-shrink: 1;
    `: css`
      flex-shrink: 1;
      min-width: 0;
      :hover {
        background: ${props => darken(0.05, getColor('gray240')(props))};
        cursor: pointer;
      }
    `}
    ${Icon} {
      font-size: 22px;
      margin-right: 0.75rem;
      flex-shrink: 0;
    }
    .tabName {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
`
const CloseTabIcon = styled(Icon).attrs({ icon: 'x' })`
  font-size: 16px !important;
  margin-right: -0.5rem !important;
  margin-left: 0.25rem;
  text-align: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 100px;
  cursor: pointer;
  :hover {
    background: rgba(0,0,0,0.1);
  }
`

const LoadingAction = styled.div`
  color: rgba(0,0,0,0.3);
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  ${Loading} {
    margin-right: 0.5rem;
  }  
`


const RecordsDialog = styled(Dialog)`
  max-width: 90rem;
  ${DialogContent} {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 6rem);
  }
  ${media.desktop`
    margin-top: -2rem;
    margin-bottom: -2rem;
  `}
`

const ViewDialog = styled(Dialog)`
  max-width: 100%;
  ${DialogContent} {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 6rem);
  }
  ${media.desktop`
    margin: -2rem 0.5rem;
  `}
`


const StyledTable = styled.div`
  overflow: auto;
  flex-grow: 1;
  width: 100%;
  color: ${getColor('gray60')};
  background: white;
  border: 1px solid rgba(0,0,0,0.15);
  border-spacing: 0;
  position: relative;
  ${props => props.isLoading && `
    pointer-events: none;
    ${StyledTableRow} {
      border-color: transparent;
      > * {
        opacity: 0.1;
        transition: 0.5s ease;
      }
    }
  `}
  ${media.desktop`
    border-radius: 5px;
  `}
  ${props => props.isMobile && css`
      overflow-x: auto;
  `}
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background: rgba(0,0,0,0.05);
    border-radius: 0 5px 5px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.25);
    border-radius: 100px;
    background-clip: padding-box;
    border: 4px solid transparent;
    min-height: 30px;
    min-width: 30px;
    &:hover {
      background: rgba(0,0,0,0.35);
      background-clip: padding-box;
      border: 4px solid transparent;
    }
  }
`

const StyledTableRow = styled.div`
  min-height: 4rem;
  position: relative;
  border-bottom: 1px solid rgb(225,225,225);
  display: flex;
  align-items: center;
  ${props => props.isSelected && css`
    background: ${props => lighten(0.475, props.theme.colors.success)} !important;
  `}

  ${props => props.isMobile && css`
    min-width: 100%;
  `}
  ${props => props.isEven && css`
    background-color: #EFF4F7;
  `}
}
`
export const StyledTableHead = styled(StyledTableRow)`
  max-width: 100%;
  align-items: center;
  font-weight: 600;
  background: white;
  top: 0;
  position: sticky;
  z-index: 15;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  border-color: rgba(0,0,0,0.15) !important;
  > * {
    opacity: 1 !important;
  }
  .tools {
    border-top-right-radius: 5px;
    ${props => props.isMobile && `
      height: 4rem;
    `}
  }
`
export const StyledTableColumn = styled.div`
  flex-basis: 0;
  flex-grow: 2;
  padding: 0.75rem 0.75rem;
  :first-child {
    padding-left: 1rem;
  }
  :last-child {
    padding-right: 1rem;
  }
  &.institution {
    flex-grow: 2;
  }
  &.record {
    flex-grow: 2;
  }
  &.type {
    flex-grow: 1;
  } 
  &.date {
    flex-grow: 1;
    width: 12px;
    flex-basis: inherit;
  }
  &.action{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 130px;
  }
  &.tools {
    flex-grow: 0;
    width: 72px;
    margin-left: 0px;
    min-width: 46px;
    flex-basis: inherit;
    text-align: right;
    position: sticky;
    right: 0rem;
    z-index: 10;
    :before {
      transition: all 0.5s ease, opacity 0ms;
      content: '';
      position: absolute;
      width: 0;
      left: 0;
      bottom: 0;
      top: 0;
      pointer-events: none;
      border-right: 1px solid rgba(0,0,0,0.1);
      opacity: 0;
    }
  }
  ${props => props.sortable && `
    cursor: pointer;
  `}
`

const CustomHelpText = styled.p`
  color: rgba(0, 0, 0, 0.4);
  display: block;
  font-size: 12px;
  margin: 0.25rem 0 0;
  font-weight: 400;
`

const ImportButton = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: ${props => props.selected ? '1px' : '0.25rem'};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: ${props => props.selected ? getColor('success') : getColor('secondary')};
  ${Icon} {
    margin-right: 0.25rem;
    font-size: 26px;
  }
  a {
    text-decoration: underline;
    color: ${getColor('gray90')};
    font-weight: 400;
    margin-top: -3px;
    display: block;
  }
`

const ToolButtonView = styled.a`
  text-decoration: none;
  color: inherit;
  ${Icon} {
    font-size: 26px;
    color: ${getColor('secondary')};
  }   
`

// const IFRAME_ROOT = process.env.NODE_ENV === 'development' ? '../../..' : '.'
const IFRAME_ROOT = '../../..'

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // Extract Base64 string, remove data URL header
      const base64String = reader.result
        .replace(/^data:.+;base64,/, '');
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const ReportFrame = styled.iframe`
  width: 100%;
  // max-width: 210mm;
  min-width: 300px;
  min-height: 300px;
  border-radius: 4px;
  border: 1px solid ${getColor('gray180')};
  background: white;
  height: 100%;
  ${props => props.txtReport && css`
    background: white;
    padding: 2rem;
    border: 1px solid ${getColor('gray210')};
  `}
  ${props => (props.pdfReport || props.htmlReport) && css`
    height: 100%;
    width: 100%;
  `}
  ${props => props.htmlReport && css`
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  `}
  ${props => props.isCCDA && css`
    max-width: 100%;
    height: 100%;
  `}
`

const PreviewDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  color: ${getColor('gray90')};
  h2 {
    margin-bottom: 0.25rem;
    font-size: 18px;
  }
  .patientInfo {
    font-size: 14px;
  }
  .bullet {
    margin: 0 0.5rem;
  }
  ${CloseIcon} {
    position: relative;
    right: inherit;
    top: inherit;
    margin-left: 1rem;
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
    margin-right: -0.25rem;
    color: ${getColor('gray150')};
    background: ${getColor('gray240')};
    :hover {
      background: ${getColor('gray230')};
    }
  }
  .documentInfo {

  }
  .actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
`

const SearchParamsFirstLine = styled.div`
    font-size: 16px;
    font-weight: bold;

    a{
      color: ${getColor('main')};
    }

    span{
      color: ${getColor('gray120')};
      font-weight: normal;
      font-size: 14px;
    }
`

const SearchParamsSecondLine = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: flex-start;
  font-size: 13px;
  margin-top: 0.5rem;
  span > span{
    color: ${getColor('gray120')};
    font-weight: normal;
    font-size: 13px;
  }
  flex-wrap: wrap;
`

const PatientParamsLine = styled.div`
  display: flex; 
  flex-direction: row; 
  gap: 1rem; 
  justify-content: space-between;
  font-size: 13px;
`

const BlueLink13 = styled.a`
  color: ${getColor('main')};
  font-size: 13px;
  ${props => props.underline && `text-decoration: underline;`}
  :hover {
    color: ${getColor('main')};
    filter: brightness(1.2);
  }
`

const BlueButton13 = styled.button`
  color: ${getColor('main')};
  font-size: 13px;
  border-radius: 6px;
  border: 2px solid ${getColor('main')};
  background: transparent;
  padding: 0.5rem 1rem;
  cursor: pointer;
  :hover {
    color: ${getColor('main')};
    filter: brightness(1.2);
  }
`

const SearchParamsBox = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    background: #F2F2F2; 
    padding: 1.5rem; 
    border-radius: 5px
`

const SelectedTabCard = styled.div`
  padding: 2rem;
  border-radius: 0px 8px 8px 8px;
  height: calc(100vh - 100px);
  border: 1px solid #d1e7f5;
  overflow: auto;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background: rgba(0,0,0,0.05);
    border-radius: 0 9px 9px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.25);
    border-radius: 100px;
    background-clip: padding-box;
    border: 4px solid transparent;
    min-height: 30px;
    min-width: 30px;
    &:hover {
      background: rgba(0,0,0,0.35);
      background-clip: padding-box;
      border: 4px solid transparent;
    }
  }
`

const EmptyResultsBox = styled.div`
  padding: 2rem 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  height: 100%;
  div {
    margin-left: auto;
    margin-right: auto;
  }
`

const PatientParametersBox = styled.div`
  background: ${getColor('gray240')};
  border-radius: 8px;
  margin: auto;
  max-width: 764px;
  margin-bottom: 1rem;
  position: relative;
  padding: 1rem;
  ${props => props.isOpen && `
    padding: 2rem 2rem 1.5rem;
  `}
`

function convertMillisToLocaleString(millis) {
  const date = new Date(millis);

  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).replace(/,/g, ' -');
}


function formatDateFrom14(dateStr) {
  if (!dateStr || dateStr.length < 8) return null;
  // Format the date string from "YYYYMMDDHHMMSS" to a Date object
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(4, 6), 10) - 1; // Month is 0-indexed
  const day = parseInt(dateStr.substring(6, 8), 10);
  const hour = dateStr.length > 8 ? parseInt(dateStr.substring(8, 10), 10) : 0;
  const minute = dateStr.length > 8 ? parseInt(dateStr.substring(10, 12), 10) : 0;
  const second = dateStr.length > 8 ? parseInt(dateStr.substring(12, 14), 10) : 0;

  return new Date(year, month, day, hour, minute, second);
}

const ERROR = 'ERROR'
const SUCCESS = 'SUCCESS'
const NOT_FOUND = 'NOT_FOUND'
const DOC_FOUND = "DOC_FOUND"
const PATIENT_NOT_FOUND = "PATIENT_NOT_FOUND"
const DOC_NOT_FOUND = "DOC_NOT_FOUND"


const statusMessageFromStatusConst = (code) => {
  switch (code) {
    case DOC_FOUND:
      return 'Success';
    case ERROR:
      return <div css="display: flex; align-items: center; color: #C3282D;"><Icon icon="warning" css="font-size:20px;margin-right:0.5rem;" />Communication not established</div>
    case DOC_NOT_FOUND:
      return 'Records not found';
    case PATIENT_NOT_FOUND:
      return 'Patient not found';
    default:
      return '';
  }
}

const errorMessageFromErrorCode = (code) => {
  switch (code) {
    case 'NO_ORGS_FOUND':
      return 'No institutions were found.';
    case 'MAX_NUMBER_ORGS_EXCEEDED':
      return <>
        <p>The maximum number of organizations has been exceeded.</p>
        <p>Please decrease search radius, or be more specific in your institution name search.</p>
      </>;
    default:
      return `Error code: ${code}`;
  }
}

// Styled components
const DropdownContainer = styled.div`
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  top: 36px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const InputLoading = styled(Loading)`
  right: 0.6rem;
  left: auto;
  top: -18px;
`

const DropdownItem = styled.span`
  display: block;
  padding: 8px 12px;
  cursor: pointer;
  background: ${(props) => (props.highlighted ? '#008bd2' : 'white')};
  color: ${(props) => (props.highlighted ? 'white' : 'black')};

  &:hover {
    background: #008bd2;
    color: white;
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid #ddd; // Divider color
  }
`;

const DropdownIconContainer = styled(Icon)`
  position: absolute;
  right: 0;
  top: 14px;
  width: 3rem;
  height: 3rem;
  bottom: -1px;
  ${Icon} {
    position: absolute;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    top: 4px;
    left: 50%;
    transform: translate(-50%,-50%);
    background: rgba(0,0,0,0.1);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    color: ${getColor('gray90')};
    :hover, :active {
      background: rgba(0,0,0,0.15);
    }
  }
`;

export const ImportNewCareQualityDialog = ({ data, setNotification }) => {

  // milliseconds to keep checking for new status while in progress
  const CHECKSTATUSREFRESH = 30000

  // timeout for a consult that does not resolve from keep in progress
  const TIMEOUT = 600000 // 10 minutes

  const {
    requestId,
    refreshUrl,
    jwt,
    patientName,
    caseId,
    worklistViewerJwt: expertViewJwt,
    careQualityPatient,
    apiEndpoints: { pdfIframeSrc }
  } = useContext(UploaderConfigContext)

  const viewDocument = useToggle()

  const changeParams = useToggle()
  const changePatientParams = useToggle()
  const institutionPopup = useToggle()
  const removeSearchPopup = useToggle()
  const [isRemoving, setIsRemoving] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [ErrorProvider, errors, { setError }] = useProvideErrors()

  const doAction = useDoAction()

  const [isLoading, setIsLoading] = useState(true)
  // List of all the tabs
  const [consults, setConsults] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedConsult, setSelectedConsult] = useState(null)
  const [zip, setZip] = useState('')
  const [radius, setRadius] = useState(0)
  const [patientParams, setPatientParams] = useState({})
  const close = () => {
    doAction(UIAction.ToggleImportNewCareQualityDialog)
  }

  const [filteredRecords, setFilteredRecords] = useState([])
  const [searchText, setSearchText] = useState('')
  const tableRef = useRef()

  const [locales, setLocales] = useState([])
  const [isLoadingLocales, setIsLoadingLocales] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const dropdownItemRefs = useRef([]); // Refs for each dropdown item

  const handleItemClick = (locale) => {
    setZip(locale.zipcode);
    // setZip(`${locale.zipcode} - ${locale.locale}, ${locale.city} - ${locale?.state?.toUpperCase()}`);
    setLocales([]);
    setShowDropdown(false);
  };

  useEffect(() => {
    // Scroll the highlighted item into view when the highlighted index changes
    if (highlightedIndex >= 0 && dropdownItemRefs.current[highlightedIndex]) {
      dropdownItemRefs.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [highlightedIndex]);

  const handleItemHover = (index) => {
    setHighlightedIndex(index);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!showDropdown) {
          setShowDropdown(true);
          setHighlightedIndex(0);
        } else {
          setHighlightedIndex((prevIndex) => (prevIndex + 1) % locales.length);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : locales.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (showDropdown && highlightedIndex >= 0) {
          handleItemClick(locales[highlightedIndex]);
        }
        break;
      default:
        break;
    }
  };

  // Use useRef to store the debounced function so it's stable across renders
  const debouncedFetchLocales = useRef(
    _.debounce(500, (zip) => {
      if (zip.length > 0) {
        setHighlightedIndex(-1);
        setLocales([]);
        setIsLoadingLocales(true);
        jwtGetFetcher(expertViewJwt())(`/api/zipcodes?city=${zip}`)
          .then((res) => {
            const newLocales = res.content;
            const newLocalesUnique = _.uniqBy('zipcode', newLocales);
            setLocales(newLocalesUnique);
            setIsLoadingLocales(false);
            setShowDropdown(true); // Ensure the dropdown shows up
          })
          .catch((error) => {
            console.error('Error fetching zipcodes:', error);
            setLocales([]);
            setIsLoadingLocales(false);
          });
      }
    })
  ).current;

  useEffect(() => {
    if (zip.length > 0) {
      debouncedFetchLocales(zip);
    } else {
      setLocales([]);
      setShowDropdown(false);
    }
  }, [zip, debouncedFetchLocales]); // Dependency array ensures useEffect run

  useEffect(() => {
    jwtGetFetcher(expertViewJwt())(`/api/carequality/lastSearches?requestId=${requestId}`)
      .then(res => {

        // invert the order
        setConsults(res.reverse())
        if (res.length == 0) {
          openNewSearch()
        }
      })
      .catch(e => { console.log(e) })
      .finally(() => setIsLoading(false))
    
  }, [])

  const intervalRef = useRef(null);

  useEffect(() => {
    if (consults.length > 0) {
      if (!selectedTab) {
        setSelectedTab(consults[0].id);
      } else {
        if (!consults.find(c => c?.id === selectedTab)) {
          setSelectedTab(consults[0].id);
        }
      }
    }

    const checkStatus = () => {
      consults.forEach(consult => {
        if (consult.status === IN_PROGRESS && (consult?.whenModifiedEpochMilli + TIMEOUT) >= new Date().getTime()) {
          jwtGetFetcher(expertViewJwt())(`/api/carequality/search/${consult.id}`)
            .then(res => {
              // If the consult is completed, remove it from the consults array or mark it as completed
              if (res.status !== IN_PROGRESS) {
                // Logic to remove or update the consult in the list
                setConsults(consults => consults.map(c => (c.id === consult.id ? { ...res, type: res?.documentsQuery?.organizationName ? INSTITUTION : ZIP } : c)));
                setSelectedConsult(selectedConsult => selectedConsult?.id === consult.id ? { ...res, type: res?.documentsQuery?.organizationName ? INSTITUTION : ZIP } : selectedConsult)
              }
            })
            .catch(e => console.log(e));
        }
      });
    };

    // Start the interval to check every 30 seconds
    intervalRef.current = setInterval(checkStatus, CHECKSTATUSREFRESH);

    // Cleanup interval when the component unmounts or consults change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [consults]);

  useEffect(() => {
    changeParams.close()
    if (selectedTab > 0) {
      const newSelectedConsult = consults.filter(c => c.id === selectedTab).length > 0 ? consults.filter(c => c.id === selectedTab)[0] : null
      setSelectedConsult({
        ...newSelectedConsult,
        type: newSelectedConsult?.documentsQuery?.organizationName ? INSTITUTION : ZIP
      })
      if (newSelectedConsult?.isNew) {
        openChangeParams(newSelectedConsult)
      }
    }
  }, [selectedTab])

  const openNewSearch = () => {

    const newConsult = {
      id: new Date(),
      zip: '',
      name: '',
      type: ZIP,
      isNew: true,
      institutions: [],
      records: [],
      isLoading: false,
      hasError: false,
      documentsQuery: {
        radius: '5',
        organizationName: '',
        zipCode: '',
        patientDemographics: {
          ...careQualityPatient,
        },
      },
      whenModifiedEpochMilli: new Date().getTime()
    }
    setConsults(consults => [...consults, newConsult])
    setSelectedTab(newConsult.id)
  }

  // Function to format date from MM-DD-YYYY to YYYY-MM-DD
  const formatDate = (dateString) => {
    // Check if the date string is valid and in the expected format
    if (!dateString || typeof dateString !== 'string') {
      return '';
    }
    
    // If there is already a date in the format YYYY-MM-DD, return it
    if(dateString.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
      return dateString
    }

    const [month, day, year] = dateString.split('-');
  
    // Ensure all parts are defined and return formatted date
    if (month && day && year) {
      return `${year}-${month}-${day}`;
    }
    
    // Return an empty string if the date is not in the expected format
    return '';
  };


  const openChangeParams = (consult) => {

    setError(null)
    setZip(consult?.documentsQuery?.zipCode)
    setRadius(consult?.documentsQuery?.radius + '')

    const middleNameArray = consult?.documentsQuery?.patientDemographics?.givenName?.slice(1);
    if (consult.isNew) {
      setPatientParams({
        ...consult?.documentsQuery?.patientDemographics
      })
    } else {
      setPatientParams({
        ...consult?.documentsQuery?.patientDemographics,
        firstName: consult?.documentsQuery?.patientDemographics?.givenName?.length > 0
          ? consult?.documentsQuery?.patientDemographics?.givenName[0]
          : '',
        middleName: middleNameArray?.length > 0 ? middleNameArray?.join(' ') : '',
        lastName: consult?.documentsQuery?.patientDemographics?.familyName
      })
    }
    changeParams.open()
  }

  const handleSearch = () => {

    setError(null)

    if(selectedConsult.type === ZIP  && !zip) {
      setError("Error", 'Please enter the zip code')
      return
    }
    // zip must contain only numbers at maximum of 5 characters
    if(selectedConsult.type === ZIP && (zip.length > 5 || !zip.match(/^[0-9]+$/))) {
      setError("Error", 'Please enter a valid zip code')
      return
    }

    if (!patientParams?.firstName) {
      setError("Error", 'Please enter the first name')
      return
    }

    if (!patientParams?.lastName) {
      setError("Error", 'Please enter the last name')
      return
    }

    if (!patientParams?.birthDate) {
      setError("Error", 'Please enter the birth date')
      return
    }

    const formattedDate = new Date(formatDate(patientParams?.birthDate))?.toISOString()?.split('T')[0]
    setIsSubmitting(true)
    const oldIdDate = selectedConsult?.id
    const isNew = selectedConsult.isNew ? true : false
    const params = {
      zipCode: selectedConsult.type === ZIP ? zip : '',
      radius: selectedConsult.type === ZIP ? Number(radius) : 0,
      organizationName: selectedConsult.type === INSTITUTION ? selectedConsult?.documentsQuery?.organizationName : '',
      patientDemographics: {
        familyName: patientParams?.lastName,
        givenName: patientParams?.firstName.split(' ').filter(m => m.length > 0).concat(patientParams?.middleName?.split(' ').filter(m => m.length > 0).filter(m => m != null)),
        gender: patientParams?.gender,
        // birthDate: patientParams?.birthDate,
        birthDate: formattedDate,
        addressLine: patientParams?.addressLine,
        city: patientParams?.city,
        state: patientParams?.state,
        postalCode: patientParams?.postalCode,
        country: patientParams?.country
      }
    }

    if (!isNew) {
      // include id in params
      params.id = selectedConsult?.id
    }

    jwtPostFetcher(expertViewJwt())(`/api/carequality/queryForDocuments?requestId=${requestId}`, params, { method: isNew ? "POST" : "PUT" })
      .then(result => {
        const newConsultObject = {
          ...result,
          type: selectedConsult.type,
          name: selectedConsult.name,
          zip: zip,
          radius: radius,
          patientInfo: patientParams,
          isNew: false,
          status: IN_PROGRESS,
          documents: result.documents ? result.documents : [],
          institutions: result.institutions ? result.institutions : [],
        }
        setSelectedConsult(newConsultObject)
        if (!isNew) {
          setConsults(prev => prev.map(c => c.id === selectedConsult?.id ? newConsultObject : c))
        } else {
          setConsults([...consults, newConsultObject].filter(c => c.id !== oldIdDate))
          setSelectedTab(newConsultObject.id)
        }
        setIsSubmitting(false)
        changePatientParams.close()
        changeParams.close()
      })
      .catch(err => {
        console.log(err)
        setIsSubmitting(false)
      })

  }

  const addressFromSelectedConsult = selectedConsult ? (() => {
    const { addressLine, city, state, postalCode } = selectedConsult.documentsQuery?.patientDemographics || {};

    const addressParts = [
      addressLine,
      city,
      state ? state : null, // Ensure that state is not followed by a comma if postalCode is absent
      postalCode ? postalCode : null // Ensures that a space is before the postalCode only if it exists
    ].filter(Boolean); // Filter out any undefined or null values

    const fullAddress = addressParts.length ? addressParts.join(', ') : '';

    // Clip to 255 characters and add '...' if necessary
    const clippedAddress = fullAddress.length > 150 ? fullAddress.substring(0, 150) + '...' : fullAddress;

    return clippedAddress || null;
  })() : null;

  return <RecordsDialog
    closeDialog={close}
  >
    <DialogTitle css="margin-bottom:0.25rem;">Import records from Carequality</DialogTitle>
    <div css="font-size: 14px;color:rgba(0,0,0,0.5);margin-bottom:1.5rem;">Patient: {patientName} - Case: {caseId}</div>

    {isLoading
      ? <div css="display: flex; justify-content: center">
        <Loading size={52} borderWidth={5} absolute />
      </div>
      : <>
        <div css="display: flex; justify-content: space-between; align-items: flex-start">
          {/* Tabs */}
          <div css="display: flex; align-items: center; justify-content: flex-start; min-width: 0;">
            {consults.map((c, index) => {
              return <TabItem key={index} onClick={() => setSelectedTab(c.id)} active={c.id === selectedTab}>
                <Icon icon="search"></Icon><div className='tabName'>{c?.isNew ? 'New search' : (c?.documentsQuery?.organizationName == null || c?.documentsQuery?.organizationName == '') ? `Zip code: ${c?.documentsQuery?.zipCode}` : c?.documentsQuery?.organizationName}</div>
                <CloseTabIcon onClick={(e) => {
                  e.stopPropagation()
                  const consultToBeDeleted = consults.find(consult => consult.id === c.id)
                  // TODO: Check conditions for direct removal of a search
                  if (consultToBeDeleted.institutions.length == 0 || consultToBeDeleted.documents.length == 0 || consultToBeDeleted.isNew) {
                    var shouldOpenNew = consults.length === 1
                    if (!consultToBeDeleted.isNew) {
                      setIsRemoving(true)
                      jwtPostFetcher(expertViewJwt())(`/api/carequality/search/${c.id}`, {}, { method: 'DELETE' })
                        .then((res) => {
                          setIsRemoving(false)
                          setConsults(prev => prev.filter(consult => consult.id !== c.id))
                          if(shouldOpenNew){
                            openNewSearch()
                          }
                        })
                        .catch((e) => {
                          setIsRemoving(false)
                        })
                    } else {
                      setConsults(prev => prev.filter(consult => consult.id !== c.id))
                      if(shouldOpenNew){
                        openNewSearch()
                      }
                    }
                  } else {
                    removeSearchPopup.openWith(c.id)
                  }
                }}></CloseTabIcon>
              </TabItem>
            })}

          </div>

          <Tooltip content={consults.length < 5 ? "Add a new search" : "Close one or more search tabs to create a new one"}>
            <Button
              icon="plus"
              highlight
              disabled={consults.length >= 5}
              onClick={openNewSearch}
              css="min-width: 112px;"
            >
              New search
            </Button>
          </Tooltip>
        </div>
        <SelectedTabCard>
          {changeParams.isOpen
            ? <>
              <div css="display: flex; flex-direction: column; gap: 1rem; align-items: flex-start;">
                {/* Location parameters */}
                <div css="margin: auto">
                  <h4><b>Institution parameters</b></h4>
                  <RadioboxGroup raw horizontal>
                    <p css="margin-right: 0.5rem; margin-bottom: 0rem;">Search by: </p>
                    <SimpleRadiobox
                      raw
                      label={'Location (ZIP code)'}
                      fieldValue={ZIP}
                      value={selectedConsult?.type}
                      setValue={(value) => setSelectedConsult({ ...selectedConsult, type: value })}
                    />
                    <SimpleRadiobox
                      raw
                      label='Institution'
                      fieldValue={INSTITUTION}
                      value={selectedConsult?.type}
                      setValue={(value) => setSelectedConsult({ ...selectedConsult, type: value })}
                    />
                  </RadioboxGroup>

                  {/* <div css="display: flex; flex-direction: row; gap: 1rem; justify-content: space-between; background: lightgray; padding: 1rem 2rem; border-radius: 8px;"> */}
                  <div css="margin-left: 0px; margin-right: 0px; height: 48px; margin-top: 0px; width: 764px; margin-bottom: 1rem; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;">
                    {/* <SplitColumnsContainer 
                              viewportMinWidth={764}
                              // css="background: lightgray; padding: 1rem 2rem; border-radius: 8px; margin-left: 0px; margin-right: 0px; margin-top: 0px; width: 764px; margin-bottom: 1rem;"
                            > */}
                    {selectedConsult?.type === ZIP
                      ? <>
                        <div style={{ position: 'relative', width: '80%' }}>
                          <TextInput
                            raw
                            value={zip}
                            setValue={(value) => {
                              setZip(value)
                            }}
                            // label="Zip"
                            // descText="Type zip code or search by name"
                            // helpText ="HType zip code or search by name"
                            // bellowDescText ="Type zip code or search by name"
                            placeholder="Ex: 12345"
                            ref={inputRef}
                            onKeyDown={handleKeyDown}
                          />
                          <CustomHelpText>Type zip code or search by name</CustomHelpText>
                          {isLoadingLocales && <InputLoading absolute size={24}/>}
                          {locales && locales.length > 0 && <DropdownIconContainer onClick={toggleDropdown}>
                            <Icon icon="arrow-down"/>
                          </DropdownIconContainer>}
                          {showDropdown && locales && locales.length > 0 && <div>
                            <DropdownContainer>
                              {locales.map((locale, index) => (
                                <DropdownItem
                                  key={locale.zipcode}
                                  highlighted={index === highlightedIndex}
                                  onClick={() => handleItemClick(locale)}
                                  onMouseEnter={() => handleItemHover(index)}
                                  ref={(el) => (dropdownItemRefs.current[index] = el)}
                                >
                                  {locale.zipcode} - {locale.locale}, {locale.city} - {locale?.state?.toUpperCase()}
                                </DropdownItem>
                              ))}
                            </DropdownContainer>
                          </div>}
                        </div>
                        <Combobox
                            raw
                            // label="Tracking radius"
                            options={["5 miles", "15 miles", "30 miles"]}
                            value={radius + ' miles'}
                            setValue={(value) => setRadius(value.split(' ')[0])}
                          />
                      </>
                      : <>
                        <TextInput
                          placeholder="Ex: Regional Health Facility"
                          raw
                          value={selectedConsult?.documentsQuery?.organizationName}
                          setValue={(value) => setSelectedConsult({ ...selectedConsult, documentsQuery: { ...selectedConsult?.documentsQuery, organizationName: value } })}
                          css="flex: 6"
                        />
                      </>
                    }
                    <Button
                      icon="search"
                      highlight
                      disabled={(
                        (selectedConsult?.type === INSTITUTION && !selectedConsult?.documentsQuery?.organizationName)
                        || (selectedConsult?.type === ZIP && !zip)
                      )}
                      isLoading={isSubmitting}
                      onClick={() => handleSearch()}
                      css="min-width: 0px;
                          width: 2rem;
                          max-width: 40px;
                          height: 30px;
                          padding: 1rem 1rem 1rem 2rem;
                          text-align: center;"
                    />
                    {/* </SplitColumnsContainer> */}
                  </div>
                </div>

                {/* Patient parameters */}
                <div css="margin: auto">
                  <h4><b>Patient parameters</b></h4>
                  <p>You can refine patient demographic information to improve record matching. These changes won't be propagated to the case.</p>
                  <PatientParametersBox isOpen={!selectedConsult?.isNew || changePatientParams.isOpen}>
                    {selectedConsult?.isNew && !changePatientParams.isOpen
                      ? <>
                        <SearchParamsSecondLine>
                          <span><b>Patient:</b> {selectedConsult?.documentsQuery?.patientDemographics?.firstName} {selectedConsult?.documentsQuery?.patientDemographics?.middleName ? selectedConsult?.documentsQuery?.patientDemographics?.middleName + ' ' : ''}{selectedConsult?.documentsQuery?.patientDemographics?.lastName} <span>({GENDER_LABELS[selectedConsult?.documentsQuery?.patientDemographics?.gender] || ''})</span></span>
                          <span css="margin-left: 1rem;"><b>DOB: </b> {selectedConsult?.documentsQuery?.patientDemographics?.birthDate}</span>
                        </SearchParamsSecondLine>
                        <PatientParamsLine>
                          <span><b>Address: </b>{addressFromSelectedConsult}</span>
                          <BlueLink13 style={{ minWidth: '40px' }} onClick={() => changePatientParams.open()}>Edit <Icon icon="arrow-down" /></BlueLink13>
                        </PatientParamsLine>
                      </>
                      : <>
                        {selectedConsult?.isNew && <BlueLink13 css="text-align: right; width: 100%; display: block; margin: 0.25rem 0rem;" onClick={() => changePatientParams.close()}>Collapse <Icon icon="arrow-up" /></BlueLink13>}
                        <SplitColumnsContainer
                          viewportMinWidth={764}
                        // css="background: lightgray; padding: 1rem 2rem; border-radius: 8px; margin-left: 0px; margin-right: 0px; margin-top: 0px; width: 764px; margin-bottom: 1rem;"
                        >
                          <TextInput
                            label="First Name"
                            mandatory
                            placeholder="Ex: John"
                            raw
                            value={patientParams?.firstName}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, firstName: e }))}
                          />
                          <TextInput
                            label="Middle Name"
                            placeholder="Ex: James"
                            raw
                            value={patientParams?.middleName}
                            setValue={(e) => {
                              setPatientParams(prev => ({ ...prev, middleName: e }))
                            }}
                          />
                          <TextInput
                            label="Last Name"
                            mandatory
                            placeholder="Ex: Doe"
                            raw
                            value={patientParams?.lastName}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, lastName: e }))}
                          />
                        </SplitColumnsContainer>
                        <SplitColumnsContainer
                          viewportMinWidth={764}
                        // css="background: lightgray; padding: 1rem 2rem; border-radius: 8px; margin-left: 0px; margin-right: 0px; margin-top: 0px; width: 764px; margin-bottom: 1rem;"
                        >
                          <DatePicker
                            weekStartsMonday
                            label="Date of birth"
                            placeholder={`Ex: ${DATE_FORMAT().toUpperCase()}`}
                            format={DATE_FORMAT()}
                            mandatory
                            raw
                            value={patientParams?.birthDate ? new Date(formatDate(patientParams?.birthDate)) : new Date()}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, birthDate: e ? new Date(e).toISOString().split('T')[0] : null }))}
                          />
                          <Combobox
                            className="combobox"
                            raw
                            placeholder="Please select"
                            label={"Gender"}
                            options={[MALE, FEMALE, UNKNOWN, OTHER]}
                            getLabel={v => GENDER_LABELS[v]}
                            value={patientParams?.gender}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, gender: e }))}
                          />
                        </SplitColumnsContainer>
                        <SplitColumnsContainer
                          viewportMinWidth={764}
                        >
                          <TextInput
                            label="Country"
                            placeholder="Ex: USA"
                            raw
                            value={patientParams?.country}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, country: e }))}
                          />
                          <TextInput
                            label="State"
                            // descText = "Please enter the state code (2 characters)"
                            helpText="(2 characters)"
                            placeholder="Ex: CA"
                            raw
                            value={patientParams?.state}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, state: e }))}
                          />
                        </SplitColumnsContainer>
                        <SplitColumnsContainer
                          viewportMinWidth={764}
                        >
                          <TextInput
                            label="City"
                            placeholder="Ex: New York"
                            raw
                            value={patientParams?.city}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, city: e }))}
                          />
                          <TextInput
                            label="Zip code"
                            placeholder="Ex: 12321"
                            raw
                            value={patientParams?.postalCode}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, postalCode: e }))}
                          />
                        </SplitColumnsContainer>
                        <SplitColumnsContainer
                          viewportMinWidth={764}
                        >
                          <TextInput
                            label="Street address"
                            placeholder="Ex: 001 Street Name"
                            raw
                            value={patientParams?.addressLine}
                            setValue={(e) => setPatientParams(prev => ({ ...prev, addressLine: e }))}
                          />
                        </SplitColumnsContainer>
                      </>
                    }
                  </PatientParametersBox>
                  <ErrorProvider value={errors}>
                    <Errors style={{ marginTop: "0.5rem", width: "100%", margin: "0 auto" }} />
                  </ErrorProvider>
                </div>

                <div css="width: 764px; text-align: right; margin: 0 auto">
                  {!selectedConsult?.isNew && <Button
                    css="margin-bottom: 0.25rem"
                    onClick={() => {
                      // Maybe cancel button should not be on new consults, as there's no results yet, unless user starts a consult
                      // If this is a new consult, we should remove the isNew flag
                      // if(selectedConsult?.isNew) {
                      //   setSelectedConsult(prev => ({ ...prev, isNew: false }))
                      //   changePatientParams.close()
                      // }
                      changeParams.close()
                    }}
                  >
                    Cancel
                  </Button>}
                  <Button
                    highlight
                    css="margin-bottom: 0.25rem"
                    isLoading={isSubmitting}
                    disabled={(
                      (selectedConsult?.type === INSTITUTION && !selectedConsult?.documentsQuery?.organizationName)
                      || (selectedConsult?.type === ZIP && !zip)
                    )}
                    onClick={() => {
                      handleSearch()
                      // setConsults(prev => prev.map(c => c.id === selectedConsult?.id ? { ...c, isLoading: true } : c))
                      // setSelectedConsult(consult => ({ ...consult, isLoading: true }))
                      // changeParams.close()
                    }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </>
            : <>
              <SearchParamsBox>
                <div>
                  <SearchParamsFirstLine>Search using {(selectedConsult?.documentsQuery?.organizationName == null || selectedConsult?.documentsQuery?.organizationName === '')
                    ? <>zip code: <a onClick={() => openChangeParams(selectedConsult)}>{selectedConsult?.documentsQuery?.zipCode}</a> <span>({selectedConsult?.documentsQuery?.radius} miles radius)</span></>
                    : <>institution: <a onClick={() => openChangeParams(selectedConsult)}>{selectedConsult?.documentsQuery?.organizationName}</a></>
                  } </SearchParamsFirstLine>
                  {/* <div>Search using zip code: <a>{consults.find(c => c.id === selectedTab)?.zip}</a> <span>({consults.find(c => c.id === selectedTab)?.radius} miles radius)</span></div> */}
                  <SearchParamsSecondLine>
                    <span><b>Patient:</b> {selectedConsult?.documentsQuery?.patientDemographics?.givenName?.join(' ')} {selectedConsult?.documentsQuery?.patientDemographics?.familyName} <span>({GENDER_LABELS[selectedConsult?.documentsQuery?.patientDemographics?.gender] || ''})</span></span>
                    <span style={{ margin: '0 1rem' }}><b>DOB: </b> {selectedConsult?.documentsQuery?.patientDemographics?.birthDate}</span>
                    {/* <span><b>Address: </b>{selectedConsult?.documentsQuery?.patientDemographics?.addressLine ? selectedConsult?.documentsQuery?.patientDemographics?.addressLine + ', ': ''}{selectedConsult?.documentsQuery?.patientDemographics?.city ? selectedConsult?.documentsQuery?.patientDemographics?.city + ', ': ''}{selectedConsult?.documentsQuery?.patientDemographics?.state ? selectedConsult?.documentsQuery?.patientDemographics?.state + '': ''}{selectedConsult?.documentsQuery?.patientDemographics?.postalCode ? ' ' + selectedConsult?.documentsQuery?.patientDemographics?.postalCode : ''}</span> */}
                    <span><b>Address: </b>{addressFromSelectedConsult}</span>
                  </SearchParamsSecondLine>
                </div>

                <BlueButton13 css="min-width: 198px" onClick={() => {
                  // changeParams.open()
                  openChangeParams(selectedConsult)
                }}><b>Change search parameters</b></BlueButton13>

              </SearchParamsBox>

              {/* Records found */}
              <div css="margin-top: 2rem; display: flex; flex-direction: column; gap: 0.25rem; margin-bottom: 1rem">
                <div css="font-size: 15px"><b>{selectedConsult?.documents?.length} records found</b></div>
                <div css="font-size: 13px">{selectedConsult?.institutions?.length} institutions tracked {selectedConsult?.institutions?.length > 0 ? <BlueLink13 onClick={() => institutionPopup.open()}>View list</BlueLink13> : ''}</div>
              </div>

              {/* Table */}
              <StyledTable ref={tableRef} >
                {selectedConsult?.status == IN_PROGRESS
                  ? (selectedConsult?.whenModifiedEpochMilli + TIMEOUT) < new Date().getTime()
                    ? <EmptyResultsBox>
                      <div>
                        <h4>This search could not be completed.</h4>
                        <p>Please try refreshing the search.</p>
                        <Button highlight onClick={() => {
                          jwtPostFetcher(expertViewJwt())(`/api/carequality/refreshQueryForDocuments/${selectedConsult.id}`, {})
                            .then((res) => {
                              const newSelectedConsult = {
                                ...selectedConsult,
                                status: IN_PROGRESS,
                                whenModifiedEpochMilli: new Date().getTime(),
                                documents: [],
                                institutions: []
                              }
                              setSelectedConsult(newSelectedConsult)
                              setConsults(prev => prev.map(c => c.id === selectedConsult?.id ? newSelectedConsult : c))
                            })
                        }}>
                          Refresh
                        </Button>
                      </div>
                    </EmptyResultsBox>
                    : <div css="display: flex;flex-direction: column;align-content: center;align-items: center;justify-content: center;min-height: 100%;height: auto;font-size: 13px;">
                      <p>The search has started. This may take a few minutes.</p>
                      <p>In the meantime, you can close this screen and continue your work.</p>
                      <Loading size={40} borderWidth={6} css="margin-bottom: 1rem;" />
                      <Button onClick={() => {
                        removeSearchPopup.openWith(selectedConsult.id)
                      }}>
                        Cancel search
                      </Button>

                    </div>
                  : selectedConsult?.documents?.length > 0
                    ? <>
                      <StyledTableHead >
                        <StyledTableColumn sortable className="institution" onClick={() => {
                          setSelectedConsult(prev => ({
                            ...prev,
                            sortDocsBy: prev?.sortDocsBy === 'institution' ? 'institution desc' : 'institution',
                            documents: prev?.sortDocsBy === 'institution'
                              ? prev.documents.sort((a, b) => b.institution.localeCompare(a.institution))
                              : prev.documents.sort((a, b) => a.institution.localeCompare(b.institution))

                          }))
                        }}>Institution {selectedConsult?.sortDocsBy?.includes('institution') ? selectedConsult?.sortDocsBy?.includes('desc') ? <Icon icon="arrow-down"></Icon> : <Icon icon="arrow-up"></Icon> : null}</StyledTableColumn>
                        <StyledTableColumn sortable className="record" onClick={() => {
                          setSelectedConsult(prev => ({
                            ...prev,
                            sortDocsBy: prev?.sortDocsBy === 'title' ? 'title desc' : 'title',
                            documents: prev?.sortDocsBy === 'title'
                              ? prev.documents.sort((a, b) => b.title.localeCompare(a.title))
                              : prev.documents.sort((a, b) => a.title.localeCompare(b.title))
                          }))
                        }}>Document {selectedConsult?.sortDocsBy?.includes('title') ? selectedConsult?.sortDocsBy?.includes('desc') ? <Icon icon="arrow-down"></Icon> : <Icon icon="arrow-up"></Icon> : null}</StyledTableColumn>
                        <StyledTableColumn sortable className="type" onClick={() => {
                          setSelectedConsult(prev => ({
                            ...prev,
                            sortDocsBy: prev?.sortDocsBy === 'type' ? 'type desc' : 'type',
                            documents: prev?.sortDocsBy === 'type'
                              ? prev.documents.sort((a, b) => b.type.localeCompare(a.type))
                              : prev.documents.sort((a, b) => a.type.localeCompare(b.type))
                          }))
                        }}>Type {selectedConsult?.sortDocsBy?.includes('type') ? selectedConsult?.sortDocsBy?.includes('desc') ? <Icon icon="arrow-down"></Icon> : <Icon icon="arrow-up"></Icon> : null}</StyledTableColumn>
                        <StyledTableColumn className="date">Creation date</StyledTableColumn>
                        <StyledTableColumn className="tools"> </StyledTableColumn>
                        <StyledTableColumn className="action"> </StyledTableColumn>
                      </StyledTableHead>
                      {selectedConsult?.documents?.map((record, i) =>
                        <RecordRow
                          record={record}
                          jwt={jwt}
                          isEven={i % 2 === 0}
                          expertViewJwt={expertViewJwt}
                          selectedConsult={selectedConsult}
                          setSelectedConsult={setSelectedConsult}
                          consults={consults}
                          setConsults={setConsults}
                          viewDocument={viewDocument}
                          setNotification={setNotification}
                          doAction={doAction}
                        />
                      )}
                    </>
                    : selectedConsult?.status == FAILED
                      ? <EmptyResultsBox>
                        <div>
                          <h4>This consult failed</h4>
                          {selectedConsult?.errorCode &&
                            <div css="margin-bottom: 1rem;">
                              {errorMessageFromErrorCode(selectedConsult?.errorCode)}
                            </div>
                          }
                          <div>You can try to refine your search by <BlueLink13 underline onClick={() => openChangeParams(selectedConsult)}>changing the search parameters</BlueLink13>.</div>
                        </div>
                      </EmptyResultsBox>
                      : <EmptyResultsBox>
                        <div>
                          <h4>No results found for these search parameters.</h4>
                          <div>You can try to refine your search by <BlueLink13 underline onClick={() => openChangeParams(selectedConsult)}>changing the search parameters</BlueLink13>.</div>
                        </div>
                      </EmptyResultsBox>
                }
              </StyledTable>
            </>
          }
        </SelectedTabCard>
        {selectedConsult?.whenModifiedEpochMilli &&
          <div css="display: flex; flex-direction: row; gap: 1rem; align-items: center; justify-content: flex-start; margin-top: 1rem; font-size: 13px;">
            <span>Updated on: {convertMillisToLocaleString(selectedConsult?.whenModifiedEpochMilli)}</span>
            <span><b><BlueLink13 onClick={() => {
              jwtPostFetcher(expertViewJwt())(`/api/carequality/refreshQueryForDocuments/${selectedConsult.id}`, {})
                .then((res) => {
                  const newSelectedConsult = {
                    ...selectedConsult,
                    status: IN_PROGRESS,
                    whenModifiedEpochMilli: new Date().getTime(),
                    documents: [],
                    institutions: []
                  }
                  setSelectedConsult(newSelectedConsult)
                  setConsults(prev => prev.map(c => c.id === selectedConsult?.id ? newSelectedConsult : c))
                })
            }}>Refresh</BlueLink13></b></span>
          </div>
        }
      </>
    }

    {institutionPopup.isOpen && <Dialog
      closeDialog={institutionPopup.close}
      css="max-width:62rem;"
    >
      <DialogTitle>{selectedConsult?.institutions?.length} institutions tracked - {selectedConsult?.documents?.length} records found</DialogTitle>
      <StyledTable ref={tableRef} css="max-height: calc(100vh - 15rem);">
        <StyledTableHead >
          <StyledTableColumn
            sortable
            className="institution"
            onClick={() => {
              setSelectedConsult(prev => ({
                ...prev,
                sort: prev?.sort === 'name' ? 'name desc' : 'name',
                institutions: prev?.sort === 'name'
                  ? prev.institutions.sort((a, b) => b.name.localeCompare(a.name))
                  : prev.institutions.sort((a, b) => a.name.localeCompare(b.name))
              }))
            }}
          >Institution {selectedConsult?.sort?.includes('name') ? selectedConsult?.sort?.includes('desc') ? <Icon icon="arrow-down"></Icon> : <Icon icon="arrow-up"></Icon> : null}</StyledTableColumn>
          <StyledTableColumn
            sortable
            className="record"
            onClick={() => {
              setSelectedConsult(prev => ({
                ...prev,
                sort: prev?.sort === 'status' ? 'status desc' : 'status',
                institutions: prev?.sort === 'status'
                  ? prev.institutions.sort((a, b) => b.status.localeCompare(a.status))
                  : prev.institutions.sort((a, b) => a.status.localeCompare(b.status))
              }))
            }
            }>Status {selectedConsult?.sort?.includes('status') ? selectedConsult?.sort?.includes('desc') ? <Icon icon="arrow-down"></Icon> : <Icon icon="arrow-up"></Icon> : null}</StyledTableColumn>
          <StyledTableColumn
            sortable
            className="date"
            onClick={() => {
              setSelectedConsult(prev => ({
                ...prev,
                // sort: prev?.sort === 'desc' ? 'asc' : 'desc',
                sort: prev?.sort === 'size' ? 'size desc' : 'size',
                // institutions: prev.institutions.sort((a, b) => b.size - a.size)
                institutions: prev?.sort === 'size'
                  ? prev.institutions.sort((a, b) => b.size - a.size)
                  : prev.institutions.sort((a, b) => a.size - b.size)
              }))
            }
            }
          >Number of records {selectedConsult?.sort?.includes('size') ? selectedConsult?.sort?.includes('desc') ? <Icon icon="arrow-down"></Icon> : <Icon icon="arrow-up"></Icon> : null}</StyledTableColumn>
        </StyledTableHead>

        {/* {selectedConsult?.institutions?.map((i, index) => <p key={index}>{i.name}</p>)} */}
        {selectedConsult?.institutions?.map((i, index) => <StyledTableRow isSelected={false} isEven={index % 2 === 0}>
          <StyledTableColumn className="institution">
            <b>{i.name}</b>
          </StyledTableColumn>
          <StyledTableColumn className="record">
            {(i.size === 0 && i.status == ERROR && i.errorMessage != null)
              ? <Tooltip content={i.errorMessage}>{statusMessageFromStatusConst(i.status)}</Tooltip>
              : statusMessageFromStatusConst(i.status)
            }
            {/* <b>{i.status}</b> */}
          </StyledTableColumn>
          <StyledTableColumn className="date">
            {i.size.toString().padStart(2, '0')}
          </StyledTableColumn>
        </StyledTableRow>)}
      </StyledTable>
    </Dialog>}

    {removeSearchPopup.isOpen && <Dialog
      closeDialog={removeSearchPopup.close}
      title="Are you sure you want to close this search?"
      footer={<>
        <Button
          onClick={() => {
            removeSearchPopup.close()
          }}
        >Cancel</Button>
        <Button
          highlight
          isLoading={isRemoving}
          onClick={() => {
            setIsRemoving(true)
            jwtPostFetcher(expertViewJwt())(`/api/carequality/search/${removeSearchPopup.isOpen}`, {}, { method: 'DELETE' })
              .then((res) => {
                let shouldOpenNew = consults.length === 1
                setIsRemoving(false)
                removeSearchPopup.close()
                setConsults(prev => prev.filter(c => c.id !== removeSearchPopup.isOpen))
                if (shouldOpenNew) {
                  openNewSearch()
                }
              })
              .catch((e) => {
                setIsRemoving(false)
              })
          }}
        >Close search</Button>
      </>}
    >
      <p>The search in the {(consults.find(c => c.id === removeSearchPopup.isOpen)?.documentsQuery?.organizationName == null || consults.find(c => c.id === removeSearchPopup.isOpen)?.documentsQuery?.organizationName == '')
        ? <>zip code <b>{consults.find(c => c.id === removeSearchPopup.isOpen)?.documentsQuery?.zipCode}</b></>
        : <>institution <b>{consults.find(c => c.id === removeSearchPopup.isOpen)?.documentsQuery?.organizationName}</b></>
      } will be closed. To access the results again, you will need to start a new search.</p>
    </Dialog>}


    {viewDocument.isOpen && <ViewDialog
      closeDialog={false}
    >

      {/* 
      date: record.date,
      documentId: record.documentId,
      institutionName: record.institution,
      documentName: record.title,
      type: record.type,
      consultId: selectedConsult?.id, 
    */}
      <PreviewDialogHeader>
        <div className='documentInfo'>
          <h2>{viewDocument.isOpen.documentName}</h2>
          <div className='patientInfo'>
            <b>Patient:</b> {patientName}<span className='bullet'> • </span>
            <b>Case:</b> {caseId}<span className='bullet'> • </span>
            <b>Institution: </b> {viewDocument.isOpen.institutionName}
            <span>{viewDocument.isOpen.type}</span>
            {viewDocument.isOpen.creationDateHour && <><span className='bullet'> • </span><b>Date:</b> {viewDocument.isOpen.creationDateHour}</>}
          </div>
        </div>
        <div className='actions'>
          {/* {selectedCarequalityInstitutionsWithPatients
            .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find(r => r.documentId === viewDocument.isOpen.documentId)?.addedToCase */}
          {selectedConsult
            ?.documents?.find(d => d.documentId === viewDocument.isOpen.documentId)?.addedToCase
            ? <ImportButton selected={true} disabled={false}>
              <Icon icon="imported" />
              <div>
                <div>Added to case</div>
                <a onClick={() => {
                  // doAction(UploaderAction.DeleteManyByUID, [selectedCarequalityInstitutionsWithPatients
                  //   .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find(r => r.documentId === viewDocument.isOpen.documentId)?.medicalRecordID])
                  doAction(UploaderAction.DeleteManyByUID, [selectedConsult
                    ?.documents?.find(d => d.documentId === viewDocument.isOpen.documentId)?.medicalRecordID])
                  const selectedConsultWithRemovedDocument = {
                    ...selectedConsult,
                    documents: selectedConsult.documents.map(d => {
                      if (d.documentId === viewDocument.isOpen.documentId) {
                        return { ...d, addedToCase: false }
                      }
                      return d
                    })
                  }
                  setSelectedConsult(selectedConsultWithRemovedDocument)
                  setConsults(consults => {
                    return consults.map(c => {
                      if (c.id === selectedConsult.id) {
                        return selectedConsultWithRemovedDocument
                      }
                      return c
                    })
                  })
                  setNotification({
                    type: "success",
                    timeout: 4000,
                    msg: (close) => <span>Document was removed from the case</span>
                  })
                }}>Remove</a>
              </div>
            </ImportButton>

            : <Button icon="import" highlight
              isLoading={
                selectedConsult?.documents?.find(d => d.documentId === viewDocument.isOpen.documentId)?.isAddingToCase
              }
              onClick={() => {
                const clickedConsulId = selectedConsult?.id
                // build the next state after being added - before starting request, so if users leaves it wotnn change the another active tab.
                var selectedConsultAddedToCase = {
                  ...selectedConsult,
                  documents: selectedConsult.documents.map(d => {
                    if (d.documentId === viewDocument.isOpen.documentId) {
                      return { ...d, isAddingToCase: false, addedToCase: true }
                      // return { ...d, isAddingToCase: false, addedToCase: true, medicalRecordID: response.medicalRecordID }
                    }
                    return d
                  })
                }
                // build the next state after not being added - before starting request, so if users leaves it wotnn change the another active tab.
                const selectedConsultNotAddedToCase = {
                  ...selectedConsult,
                  documents: selectedConsult.documents.map(d => {
                    if (d.documentId === viewDocument.isOpen.documentId) {
                      return { ...d, isAddingToCase: false }
                    }
                    return d
                  })
                }
                const selectedConsultWithAddingDocument = {
                  ...selectedConsult,
                  documents: selectedConsult.documents.map(d => {
                    if (d.documentId === viewDocument.isOpen.documentId) {
                      return { ...d, isAddingToCase: true }
                    }
                    return d
                  })
                }
                setSelectedConsult(selectedConsultWithAddingDocument)
                setConsults(consults => {
                  return consults.map(c => {
                    if (c.id === clickedConsulId) {
                      return selectedConsultWithAddingDocument
                    }
                    return c
                  })
                })

                jwtPostFetcher(expertViewJwt())(`/api/carequality/addSingleDocument?storageToken=${jwt()}`, {
                  documentId: viewDocument.isOpen.documentId,
                  careQualitySearchId: selectedConsult?.id,
                  storageToken: jwt()
                })
                  .then((response) => {

                    let recordClass = ''
                    let format = ''
                    let fileType = ''
                    let fileExtension = ''

                    if (viewDocument.isOpen.type === 'image/tiff') {
                      recordClass = "Pathology"
                      format = "TIFF"
                      fileType = "image/tiff"
                      fileExtension = "tiff"
                    }
                    if (viewDocument.isOpen.type === 'text/plain') {
                      recordClass = "Clinical",
                        format = "TXT"
                      fileType = "text/plain"
                      fileExtension = "txt"
                    }
                    if (viewDocument.isOpen.type === 'text/xml' || viewDocument.isOpen.type === 'application/xml') {
                      recordClass = "Clinical",
                        format = "CCDA"
                      fileType = "application/xml"
                      fileExtension = "xml"
                    }
                    if (viewDocument.isOpen.type === 'application/pdf') {
                      recordClass = "Clinical",
                        format = "PDF"
                      fileType = "application/pdf"
                      fileExtension = "pdf"
                    }
                    const newRecord = {
                      recordUID: response.medicalRecordID,
                      recordClass: recordClass,
                      format: format,
                      fileType: fileType,
                      fileExtension: fileExtension,
                      info: {
                        type: "NonDicomFile",
                        fileName: "",
                        fileExtension: fileExtension,
                        fileType: fileType,
                        // fileSize: 
                        uid: response.uuid,
                        description: viewDocument.isOpen.documentName,
                        docType: "",
                        locationFormId: 0,
                        // uploadDate: now().getTime(),
                      },
                      metadata: {

                      },
                      notes: {
                        note: "",
                      },
                      state: "Submitted",
                      stateContext: {
                        Submitted: {
                          by: "",
                          on: new Date().getTime(),
                        }
                      },
                      quarantined: false,
                      id: response.medicalRecordID,
                      caseUID: '',
                      whenCreatedEpochMilli: new Date().getTime(),
                      whenModifiedEpochMilli: new Date().getTime(),
                    }
                    if (response && response?.success == true) {
                      doAction(UploaderAction.LinkCarequalityFileAsRecord, newRecord)
                      setNotification({
                        type: "success",
                        timeout: 4000,
                        msg: (close) => <span>Document was added to the case</span>
                      })
                      // const selectedConsultAddedToCase = {
                      //   ...selectedConsult,
                      //   documents: selectedConsult.documents.map(d => {
                      //     if (d.documentId === viewDocument.isOpen.documentId) {
                      //       return { ...d, isAddingToCase: false, addedToCase: true, medicalRecordID: response.medicalRecordID }
                      //     }
                      //     return d
                      //   })
                      // }
                      selectedConsultAddedToCase.documents = selectedConsultAddedToCase.documents.map(d => {
                        if (d.documentId === viewDocument.isOpen.documentId) {
                          return { ...d, isAddingToCase: false, addedToCase: true, medicalRecordID: response.medicalRecordID }
                        }
                        return d
                      })
                      setSelectedConsult(selectedConsult => clickedConsulId === selectedConsult.id ? selectedConsultAddedToCase : selectedConsult)
                      setConsults(consults => {
                        return consults.map(c => {
                          if (c.id === clickedConsulId) {
                            return selectedConsultAddedToCase
                          }
                          return c
                        })
                      })
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    setSelectedConsult(selectedConsult => clickedConsulId === selectedConsult.id ? selectedConsultNotAddedToCase : selectedConsult)
                    setConsults(consults => {
                      return consults.map(c => {
                        if (c.id === clickedConsulId) {
                          return selectedConsultNotAddedToCase
                        }
                        return c
                      })
                    })
                    setNotification({
                      type: "alert",
                      timeout: 4000,
                      msg: (close) => <span>It was not possible to add the document to the case</span>
                    })
                  })
              }}>Add to case
            </Button>
          }
          <CloseIcon onClick={viewDocument.close} />
        </div>
      </PreviewDialogHeader>

      {/* TIFF Viewer */}
      {viewDocument.isOpen?.type == 'image/tiff' &&
        <ReportFrame isCCDA src={IFRAME_ROOT + '/public/tif/index.html'} /> // Tif html viewer
      }

      {/* CCDA Viewer */}
      {(viewDocument.isOpen?.type == 'text/xml' || viewDocument.isOpen.type == 'application/xml') &&
        <ReportFrame isCCDA src={IFRAME_ROOT + '/public/ccda/index.html'} />
      }

      {/* Text Viewer */}
      {viewDocument.isOpen?.type === 'text/plain' && viewDocument.isOpen.data &&
        <ReportFrame txtReport as="div" dangerouslySetInnerHTML={{ __html: viewDocument.isOpen.data }} />
      }

      {/* PDF Viewer */}
      {viewDocument.isOpen?.type == 'application/pdf' &&
        <ReportFrame pdfReport src={`data:application/pdf;base64,${viewDocument.isOpen.data}`} />
      }
    </ViewDialog>}
  </RecordsDialog>

}


const RecordRow = ({ record, isEven, linkRecord, providerJwt, jwt,
  setNotification, setLoadingAction, isLoadingAction, viewerLoadUrl, storageHost,
  selectedConsult, setSelectedConsult, consults, setConsults,
  expertViewJwt, viewDocument, doAction }) => {

  const [isLoadingInfo, setLoadingInfo] = useState(false);

  let creationDateHour = ''
  if (record.date) {
    creationDateHour = convertMillisToLocaleString(record.date)
  }
  // TODO tetst other provider and check date format 
  // if (record.date && record.date.length >= 8) {
  //   creationDateHour = record.date.slice(0, 4) + "-" + record.date.slice(4, 6) + "-" + record.date.slice(6, 8)
  //   if ((record.date.slice(8, 10) == "00" && record.date.slice(10, 12) == "00" && record.date.slice(12, 14) == "00")
  //     || (record.date.length == 8)) {

  //   } else {
  //     creationDateHour = creationDateHour + ' ' + record.date.slice(8, 10) + ":" + record.date.slice(10, 12) + ":" + record.date.slice(12, 14)
  //   }
  // }


  return <StyledTableRow isSelected={false} isEven={isEven}>
    <StyledTableColumn className="institution fs-exclude">
      <b>{record?.institution}</b>
    </StyledTableColumn>
    <StyledTableColumn className="record fs-exclude">
      <b>{record?.title}</b>
    </StyledTableColumn>
    <StyledTableColumn className="type fs-exclude">
      {record.type ? record.type : ""}
    </StyledTableColumn>
    <StyledTableColumn className="date fs-exclude">
      {creationDateHour}
      {/* {record.creationTime && record.creationTime.length >= 8 ? record.creationTime.slice(0, 4) + "-" + record.creationTime.slice(4, 6) + "-" + record.creationTime.slice(6, 8) : ""} */}
    </StyledTableColumn>
    <StyledTableColumn className="tools">
      <ToolButtonView
        disabled={record.isLoading}
        target={"_top"}
        onClick={() => {
          const selectedConsultWithLoadingDocument = {
            ...selectedConsult,
            documents: selectedConsult.documents.map(d => {
              if (d.documentId === record.documentId) {
                return { ...d, isLoading: true }
              }
              return d
            })
          }
          setSelectedConsult(selectedConsultWithLoadingDocument)
          setConsults(consults => {
            return consults.map(c => {
              if (c.id === selectedConsult.id) {
                return selectedConsultWithLoadingDocument
              }
              return c
            })
          })

          fetch("/api/carequality/retrieveSingleDocument", {
            "headers": {
              "accept": "*/*",
              "authorization": `Bearer ${expertViewJwt()}`,
              "content-type": "application/json; charset=UTF-8",
            },
            "body": JSON.stringify({
              documentId: record.documentId,
              careQualitySearchId: selectedConsult?.id,
            }),
            "method": "POST",
          }).then(response => {
            if (record?.type === 'image/tiff' || record?.type === 'application/pdf') {
              return response.blob()
            } else {
              return response.text();
            }
          }).then(blob => {

            let data = blob
            if (record?.type === 'text/plain') {
              data = data.replace(/\n/g, "<br/>")

              viewDocument.openWith({
                date: record.date,
                documentId: record.documentId,
                institutionName: record.institution,
                documentName: record.title,
                type: record.type,
                consultId: selectedConsult?.id,
                data: data
              })
            }

            if (record?.type === 'text/xml' || record?.type === 'application/xml') {
              localStorage.setItem('ccdaPreview', data);
              viewDocument.openWith({
                date: record.date,
                documentId: record.documentId,
                institutionName: record.institution,
                documentName: record.title,
                type: record.type,
                consultId: selectedConsult?.id,
                data: data
              })
            }

            if (record?.type === 'image/tiff') {
              const url = URL.createObjectURL(blob);
              localStorage.setItem('tiffSrc', url);
              viewDocument.openWith({
                date: record.date,
                documentId: record.documentId,
                institutionName: record.institution,
                documentName: record.title,
                type: record.type,
                consultId: selectedConsult?.id,
                data: data
              })
            }

            if (record?.type === 'application/pdf') {
              blobToBase64(blob).then(base64String => {
                viewDocument.openWith({
                  date: record.date,
                  documentId: record.documentId,
                  institutionName: record.institution,
                  documentName: record.title,
                  type: record.type,
                  consultId: selectedConsult?.id,
                  data: base64String
                })
              }).catch(error => {
                console.error(error);
                setNotification({
                  type: "alert",
                  timeout: 4000,
                  msg: (close) => <span>It was not possible to retrieve the document</span>
                })
              });
            }
          }).catch(err => {
            console.log(err)
            setNotification({
              type: "alert",
              timeout: 4000,
              msg: (close) => <span>It was not possible to retrieve the document</span>
            })
          })
            .finally(() => {
              const selectedConsultWithUpdatedDocument = {
                ...selectedConsult,
                documents: selectedConsult.documents.map(d => {
                  if (d.documentId === record.documentId) {
                    return { ...d, isLoading: false }
                  }
                  return d
                })
              }
              setSelectedConsult(selectedConsultWithUpdatedDocument)
              setConsults(consults => {
                return consults.map(c => {
                  if (c.id === selectedConsult.id) {
                    return selectedConsultWithUpdatedDocument
                  }
                  return c
                })
              })
            })
        }}
      ><Tooltip content="View document" >{record.isLoading ? <Loading size={20} borderWidth={3} /> : <Icon icon="view" />}</Tooltip></ToolButtonView>

    </StyledTableColumn>
    <StyledTableColumn className="action">
      {record.isAddingToCase ?
        <LoadingAction>
          <Loading size={18} borderWidth={3} /> Processing...
        </LoadingAction>
        : record.addedToCase ?
          <ImportButton selected={record.addedToCase} disabled={record.isAddingToCase}>
            <Icon icon="imported" />
            <div>
              <div>Added to case</div>
              <a onClick={() => {
                doAction(UploaderAction.DeleteManyByUID, [record.medicalRecordID])
                const selectedConsultWithRemovedDocument = {
                  ...selectedConsult,
                  documents: selectedConsult.documents.map(d => {
                    if (d.documentId === record.documentId) {
                      return { ...d, addedToCase: false }
                    }
                    return d
                  })
                }
                setSelectedConsult(selectedConsultWithRemovedDocument)
                setConsults(consults => {
                  return consults.map(c => {
                    if (c.id === selectedConsult.id) {
                      return selectedConsultWithRemovedDocument
                    }
                    return c
                  })
                })

                // setSelectedConsult(selectedConsult => {
                //   return {
                //     ...selectedConsult,
                //     documents: selectedConsult.documents.map(d => {
                //       if (d.documentId === record.documentId) {
                //         return { ...d, isAddingToCase: false, addedToCase: false}
                //       }
                //       return d
                //     })
                //   }
                // })
                setNotification({
                  type: "success",
                  timeout: 4000,
                  msg: (close) => <span>Document was removed from the case</span>
                })
              }}>Remove</a>
            </div>
          </ImportButton>
          : <ImportButton
            disabled={record.isAddingToCase}
            selected={record.addedToCase}
            onClick={() => {

              if (!record.addedToCase) {
                const clickedConsulId = selectedConsult?.id
                // build the next state after being added - before starting request, so if users leaves it wotnn change the another active tab.
                var selectedConsultAddedToCase = {
                  ...selectedConsult,
                  documents: selectedConsult.documents.map(d => {
                    if (d.documentId === viewDocument.isOpen.documentId) {
                      return { ...d, isAddingToCase: false, addedToCase: true }
                      // return { ...d, isAddingToCase: false, addedToCase: true, medicalRecordID: response.medicalRecordID }
                    }
                    return d
                  })
                }
                // build the next state after not being added - before starting request, so if users leaves it wotnn change the another active tab.
                const selectedConsultNotAddedToCase = {
                  ...selectedConsult,
                  documents: selectedConsult.documents.map(d => {
                    if (d.documentId === viewDocument.isOpen.documentId) {
                      return { ...d, isAddingToCase: false }
                    }
                    return d
                  })
                }

                const selectedConsultWithAddingDocument = {
                  ...selectedConsult,
                  documents: selectedConsult.documents.map(d => {
                    if (d.documentId === record.documentId) {
                      return { ...d, isAddingToCase: true }
                    }
                    return d
                  })
                }
                setSelectedConsult(selectedConsultWithAddingDocument)
                setConsults(consults => {
                  return consults.map(c => {
                    if (c.id === selectedConsult.id) {
                      return selectedConsultWithAddingDocument
                    }
                    return c
                  })
                })


                jwtPostFetcher(expertViewJwt())(`/api/carequality/addSingleDocument?storageToken=${jwt()}`, {
                  documentId: record.documentId,
                  careQualitySearchId: selectedConsult?.id,
                  storageToken: jwt()

                }).then((response) => {

                  let recordClass = ''
                  let format = ''
                  let fileType = ''
                  let fileExtension = ''

                  if (record.type === 'image/tiff') {
                    recordClass = "Pathology"
                    format = "TIFF"
                    fileType = "image/tiff"
                    fileExtension = "tiff"
                  }
                  if (record.type === 'text/plain') {
                    recordClass = "Clinical",
                      format = "TXT"
                    fileType = "text/plain"
                    fileExtension = "txt"
                  }
                  if (record.type === 'text/xml' || record.type === 'application/xml') {
                    recordClass = "Clinical",
                      format = "CCDA"
                    fileType = "application/xml"
                    fileExtension = "xml"
                  }
                  if (record.type === 'application/pdf') {
                    recordClass = "Clinical",
                      format = "PDF"
                    fileType = "application/pdf"
                    fileExtension = "pdf"
                  }
                  const newRecord = {
                    recordUID: response.medicalRecordID,
                    recordClass: recordClass,
                    format: format,
                    fileType: fileType,
                    fileExtension: fileExtension,
                    info: {
                      type: "NonDicomFile",
                      fileName: "",
                      fileExtension: fileExtension,
                      fileType: fileType,
                      // fileSize: 
                      uid: response.uuid,
                      description: record.title,
                      docType: "",
                      locationFormId: 0,
                      // uploadDate: now().getTime(),
                    },
                    metadata: {

                    },
                    notes: {
                      note: "",
                    },
                    state: "Submitted",
                    stateContext: {
                      Submitted: {
                        by: "",
                        on: new Date().getTime(),
                      }
                    },
                    quarantined: false,
                    id: response.medicalRecordID,
                    caseUID: '',
                    whenCreatedEpochMilli: new Date().getTime(),
                    whenModifiedEpochMilli: new Date().getTime(),

                  }
                  if (response && response?.success == true) {
                    doAction(UploaderAction.LinkCarequalityFileAsRecord, newRecord)
                    setNotification({
                      type: "success",
                      timeout: 4000,
                      msg: (close) => <span>Document was added to the case</span>
                    })

                    selectedConsultAddedToCase = {
                      ...selectedConsultAddedToCase,
                      documents: selectedConsultAddedToCase.documents.map(d => {
                        if (d.documentId === record.documentId) {
                          return { ...d, isAddingToCase: false, addedToCase: true, medicalRecordID: response.medicalRecordID }
                        }
                        return d
                      })
                    }
                    setSelectedConsult(selectedConsult => clickedConsulId === selectedConsult.id ? selectedConsultAddedToCase : selectedConsult)
                    setConsults(consults => {
                      return consults.map(c => {
                        if (c.id === clickedConsulId) {
                          return selectedConsultAddedToCase
                        }
                        return c
                      })
                    })
                  }
                })
                  .catch((error) => {
                    console.log(error)
                    setSelectedConsult(selectedConsult => clickedConsulId === selectedConsult.id ? selectedConsultNotAddedToCase : selectedConsult)
                    setConsults(consults => {
                      return consults.map(c => {
                        if (c.id === clickedConsulId) {
                          return selectedConsultNotAddedToCase
                        }
                        return c
                      })
                    })
                    setNotification({
                      type: "alert",
                      timeout: 4000,
                      msg: (close) => <span>It was not possible to add the document to the case</span>
                    })
                  })
              } else {

              }
            }}
          >
            {record.addedToCase ?
              <><Icon icon="import" /> Added to case</>
              : <>
                {record.isAddingToCase ? <Loading size={20} borderWidth={3} /> :
                  <>
                    <Icon icon="import" />
                    Add to case
                  </>
                }
              </>}
          </ImportButton>
      }
    </StyledTableColumn>
  </StyledTableRow>
}
